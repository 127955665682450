.layout{
    width: 100vw;
    height: 100vh;
    background: #FFFFFF;
}

.bottomTxt{
    font-size: 12px;
    font-family: PingFang SC Regular-Regular, PingFang SC Regular;
    font-weight: 400;
    color: #5F666B;
}
.bottom{
    height: 56px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFF;
    display: flex;
    align-items: center;
    padding-bottom: env(safe-area-inset-bottom);
    box-shadow: 1px 1px 3px #ccc;
}
.bottomBox{
    background-color: #FFF;
    padding-bottom: env(safe-area-inset-bottom);
    height: 276px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
    width: 100%;
}
.bottomTitle{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.patternCatTxt{
    font-size: 13px;
    font-family: PingFang SC Medium-Regular, PingFang SC Medium;
    font-weight: 400;
    color: #7B7B7B;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    padding-bottom: 4px;
}
.bgColorItem{
    width: 24px;
    height: 24px;
    background: #FFDFDF;
    border-radius: 50%;
    opacity: 1;
    border: 1px solid rgba(0,0,0,0.2);
}
.slideItem{
    font-size: 12px;
    font-family: PingFang SC Medium-Regular, PingFang SC Medium;
    font-weight: 400;
    color: #94969B;
    text-align: center;
}
.slideItem img{
    width: 38px; 
    height: 38px;
    border-radius: 50%; 
    /* display: block; */
    border: 1px solid #141414;
}
.cuxieItem{
    width: 43px;
    height: 43px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    color: #52575D;
    border: 1px solid #D6D8DB;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pailieDItem{
    width: 34px;
    height: 34px;
    border: solid 1px rgba(214, 216, 219, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.pailieDItem img{
    width: 50%;
}

.uploadImgBtn{
    width: 103px;
    height: 41px;
    text-align: center;
    line-height: 41px;
    background: #141414;
    box-shadow: 0px 8px 32px 0px rgba(0,0,0,0.16);
    border-radius: 209px 209px 209px 209px;
    font-size: 14px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    color: #AAFF00;
}
.aiImgBtn{
    width: 103px;
    height: 41px;
    text-align: center;
    line-height: 41px;
    background: #AAFF00;
    box-shadow: 0px 8px 32px 0px rgba(0,0,0,0.16);
    border-radius: 209px 209px 209px 209px;
    font-size: 14px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    color: #141414;
}
.koutuBtn{
    width: 58px;
    height: 28px;
    background: #F2F2F7;
    border-radius: 14px 14px 14px 14px;
    line-height: 28px;
    text-align: center;
    font-size: 13px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    color: #141414;
}