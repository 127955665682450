body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sketch-picker {
  width: calc(100% - 20px) !important;
  padding: 0 10px !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.sketch-picker > div:nth-child(1){
  height: 108px !important;
  padding-bottom: 0 !important;
}
input,textarea{
  background: none;
  outline: none;
  border: none;
  display: block;
}
input:focus,textarea:focus{
  outline: none;
  border: none;
}
/* .flexbox-fix{
  display: none !important;
} */

.nut-range-container .min{
  display: none !important;
}
.nut-range-container .max{
  display: none !important;
}
.nut-range-button-wrapper .number{
  display: none !important;
}

.nut-dialog__footer .nut-button{
  background: black !important;
  color: greenyellow;
}